var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _vm._m(0), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4 col-md-4 col-12"
  }, [_c('div', {
    staticClass: "sidebar sticky-bar p-4 rounded shadow bg-light"
  }, [_c('div', {
    staticClass: "widget mb-4 pb-4 border-bottom"
  }, [_c('router-link', {
    staticClass: "btn w-100",
    class: _vm.trustPart >= 1 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'trust-inheritance.account',
        query: {
          hibah_id: _vm.hibahId
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" Bahagian A ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("Akaun")])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.trustPart >= 2 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "tag": "button",
      "event": _vm.hibahId ? 'click' : '',
      "disabled": !_vm.hibahId,
      "to": {
        name: 'trust-inheritance.giver',
        query: {
          hibah_id: _vm.hibahId
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" Bahagian B ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("Si Mati")])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.trustPart >= 3 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "tag": "button",
      "event": _vm.hibahId ? 'click' : '',
      "disabled": !_vm.hibahId,
      "to": {
        name: 'trust-inheritance.caretaker',
        query: {
          hibah_id: _vm.hibahId
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" Bahagian C ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("Penjaga")])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.trustPart >= 4 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "tag": "button",
      "event": _vm.hibahId ? 'click' : '',
      "disabled": !_vm.hibahId,
      "to": {
        name: 'trust-inheritance.heirs',
        query: {
          hibah_id: _vm.hibahId
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" Bahagian D ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("Waris")])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.trustPart >= 5 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "tag": "button",
      "event": _vm.hibahId ? 'click' : '',
      "disabled": !_vm.hibahId,
      "to": {
        name: 'trust-inheritance.receiver',
        query: {
          hibah_id: _vm.hibahId
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" Bahagian E ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("Penerima")])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.trustPart >= 6 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "tag": "button",
      "event": _vm.hibahId ? 'click' : '',
      "disabled": !_vm.hibahId,
      "to": {
        name: 'trust-inheritance.property.immoveable',
        query: {
          hibah_id: _vm.hibahId
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" Bahagian F ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("Harta")])])], 1), _c('b-button', {
    staticClass: "d-block btn w-100 mt-1",
    class: _vm.isActive == true ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "pressed": _vm.isActive
    },
    on: {
      "click": function ($event) {
        return _vm.generatePDF(_vm.hibahId);
      },
      "update:pressed": function ($event) {
        _vm.isActive = $event;
      }
    }
  }, [_vm._v(" Ringkasan PDF ")])], 1)]), _vm.isMounted ? _c('router-view') : _vm._e()], 1)])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_c('span', [_vm._v("Amanah Harta Pusaka")])])])])])])]);
}]

export { render, staticRenderFns }